<template>
  <div>
    <div>
      <router-link :to="{ name: 'moderator.conference.create' }" class="btn btn-primary btn-sm px-2 py-2 float-right">Start New</router-link>
      <h1 class="h3 mb-3 text-gray-800">Daily Conferences</h1>
    </div>
    <div class="card">
      <div class="px-3 py-2">
        <table class="table table-responsive-md">
          <tbody>
            <tr v-for="(video, i) in videos" :key="`video-${i}`">
              <td width="80%">
                <router-link :to="{ name: 'moderator.conference.show', params: { id: video.id }}">{{ video.title }}</router-link>
              </td>
              <td>{{ $localizer.parse(video.date) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      videos: []
    }
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/moderator/conference').then(response => {
        this.videos = response.data.videos
      })
    }
  }
}
</script>